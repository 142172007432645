<template>
  <div>
    <ProDocumentBreadcrumbs
      :breadcrumbs="[{ name: title, icon: 'PROSmart-Docs' }]"
      user-role="procurer"
    />
    <pro-report
      :title="$t(title + '.Search')"
      :columns="columns"
      v-model="list"
      :row-key="key"
      :loading="loading"
      auto-search
      show-columns-setting
      showDownloadToExcel
      :file-name="$t(title)"
    />
  </div>
</template>

<script>
import ProReport from "@/components/PROSmart/Report/ProReport";
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";

export default {
  name: "ProSmartReport",
  components: { ProDocumentBreadcrumbs, ProReport },
  data() {
    return {
      columns: [],
      list: [],
      key: "",
      title: "",
      loading: true,
    };
  },
  methods: {
    load(reportName) {
      this.title = reportName;
      this.$proSmart.report
        .generateReport(this, reportName)
        .then((b) => {
          this.columns = b.columnHeaders.map((row) => {
            if (row.subColumnHeader) {
              row.subColumnHeader = row.subColumnHeader.map((subRow) => {
                subRow.i18NLabel =
                  b.title + "." + row.i18NLabel + "." + subRow.i18NLabel;
                return subRow;
              });
            }
            row.i18NLabel = b.title + "." + row.i18NLabel;
            return row;
          });
          this.list = b.list;
          this.key = b.key;
          this.title = b.title;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.$route.params && this.$route.params.reportName) {
      this.load(this.$route.params.reportName);
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      this.columns = [];
      this.list = [];
      this.title = "";
      this.loading = true;

      this.load(to.params.reportName);
      next();
    } else {
      next();
    }
  },
};
</script>
